<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Descargar Lista De Productos</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3 w-crud-form">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.office_id"
                            :items="offices"
                            label="Seleccione sucursal"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.category_id"
                            :items="categories"
                            label="Seleccione categoría"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.brand_id"
                            :items="brands"
                            label="Seleccione marca"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.unit_id"
                            :items="units"
                            label="Seleccione unidad de medida"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleDownload">
                            <v-icon size="16" class="mr-1">fas fa-download</v-icon>
                            Descargar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'DownloadProducts',
    data () {
        return {
            drawer: false,
            form: {
                office_id: null,
                category_id: null,
                brand_id: null,
                unit_id: null
            }
        }
    },
    computed: {
        ...mapState('offices', [
            'offices'
        ]),
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('brands', [
            'brands'
        ]),
        ...mapState('units', [
            'units'
        ]),
        ...mapState('products', [
            'file_name'
        ])
    },
    methods: {
        ...mapActions('products', [
            'downloadProducts'
        ]),
        show () {
            this.clearForm();
            this.drawer = !this.drawer;
        },
        clearForm () {
            this.form.office_id = null;
            this.form.category_id = null;
            this.form.brand_id = null;
            this.form.unit_id = null;
        },
        closeForm () {
            this.drawer = false;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel () {
            this.closeForm();
        },
        async handleDownload () {
            let filters_data = Object.assign({}, this.form);
            await this.downloadProducts(filters_data);
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            }
        }
    }
}
</script>

<style scoped>

</style>