<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>REGISTRAR PRODUCTOS DESDE ARCHIVO</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3 w-crud-form">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-file-input v-model="form.file"
                            ref="file"
                            label="Excel con productos"
                            prepend-icon="mdi-file-excel"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card color="#FFEBEE">
                            <v-card-title>
                                <v-icon size="24" color="blue">fas fa-info-circle</v-icon>
                                <span class="ml-1 subtitle-2">La fila 1 del archivo debe tener los siguientes datos</span>
                            </v-card-title>
                            <v-card-text>
                                <ul>
                                    <li><span class="font-weight-bold">A1:</span><span class="ml-1">NOMBRE DE PRODUCTO</span></li>
                                    <li><span class="font-weight-bold">B1:</span><span class="ml-1">SKU</span></li>
                                    <li><span class="font-weight-bold">C1:</span><span class="ml-1">CÓDIGO DE BARRAS</span></li>
                                    <li><span class="font-weight-bold">D1:</span><span class="ml-1">CATEGORIA</span></li>
                                    <li><span class="font-weight-bold">E1:</span><span class="ml-1">MARCA</span></li>
                                    <li><span class="font-weight-bold">F1:</span><span class="ml-1">PRECIO VENTA</span></li>
                                    <li><span class="font-weight-bold">G1:</span><span class="ml-1">STOCK PRINCIPAL</span></li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSave" :loading="loading">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'UploadProducts',
    data () {
        return {
            drawer: false,
            loading: false,
            form: {
                file: null
            }
        }
    },
    methods: {
        ...mapActions('notifications', [
            'store_notification'
        ]),
        ...mapActions('products', [
            'upload'
        ]),
        show () {
            this.clearForm();
            this.drawer = !this.drawer;
        },
        clearForm () {
            this.form.file = null;
        },
        closeForm () {
            this.clearForm();
            this.drawer = false;
        },
        getFormData () {
            let formData = new FormData();
            if (this.form.file) {
                formData.append("file", this.form.file, this.form.file.name);
            }

            return formData;
        },
        handleCancel () {
            this.closeForm();
        },
        async handleSave () {
            this.loading = true;
            let validate = this.existErrors();
            if (validate.error) {
                this.loading = false;
                this.store_notification({
                    type: 'error',
                    message: validate.message
                });
            } else {
                let formData = this.getFormData();
                await this.upload(formData);
                this.loading = false;
                this.closeForm();
            }                   
        },
        existErrors(){
            if (!this.form.file) {
                return {
                    error: true,
                    message: 'Debe seleccionar un archivo xlsx'
                }
            }

            if (this.form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                return {
                    error: true,
                    message: 'El archivo debe ser de tipo xlsx'
                }
            }

            return {
                error: false
            }

        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>