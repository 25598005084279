<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3 w-crud-form">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.category_id"
                            :items="categories"
                            label="Seleccione categoría"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.brand_id"
                            :items="brands"
                            label="Seleccione marca"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.unit_id"
                            :items="units"
                            label="Seleccione unidad de medida"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.name" label="Nombre" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.sku" label="SKU" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.barcode" label="Código de barras" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions} from 'vuex'

import ArrayTools from '../../helpers/ArrayTools'

export default {
    name: 'Filters',
    data() {
        return {
            drawer: false,
            titleForm: null,
            fields_show_all: true,
            fields_show: [],
            filters: {
                name: null,
                sku: null,
                barcode: null,
                category_id: null,
                brand_id: null,
                unit_id: null,
                office_id: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id'
        ]),
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('brands', [
            'brands'
        ]),
        ...mapState('units', [
            'units'
        ])
    },
    methods: {
        ...mapActions('products', [
            'getProducts',
            'changeFilterPaginated'
        ]),
        showForm(title) {
            this.filters.office_id = this.office_id;
            this.titleForm = title
            this.drawer = !this.drawer
        },
        closeForm() {
            this.drawer = false
        },
        clearData() {
            this.filters.name = null;
            this.filters.sku = null;
            this.filters.barcode = null;
            this.filters.category_id = null;
            this.filters.brand_id = null;
            this.filters.unit_id = null;
            this.filters.office_id = this.office_id;
          this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleClearFilters() {
            this.clearData()
            await this.getProducts({...this.filters, paginated: true, itemsPerPage: 10})
        },
        async handleSearch() {
          this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
            await this.getProducts({...this.filters, paginated: true, itemsPerPage: 10})
            this.closeForm()
        }
    }
}
</script>

<style scoped>

</style>