<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Productos</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-menu rounded="0" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                color="#023145"
                class="white--text float-right"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleShowCreate"
                  >Registrar manualmente</v-list-item-title
                >
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link>
                <v-list-item-title @click="handleUpload"
                  >Registrar desde archivo</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
            v-model="search_product"
            label="Buscar"
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
            @keydown="handleInputSearchByEnter"
          >
          </v-text-field>
        </v-col>
        <v-col cols="5" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="3" class="pt-1">
          <v-btn
            v-if="this.$hasPermision('productos.filters')"
            dark
            color="#023145"
            outlined
            width="140"
            class="float-right"
            @click="handleShowFilters"
          >
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
          <v-btn
            dark
            color="green"
            class="float-right mr-2"
            @click="handleDownload"
          >
            <v-icon class="mr-1" size="16">fas fa-download</v-icon>
            Descargar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-model="selectedRows"
              :headers="headers"
              :items="products"
              :items-per-page="10"
              class="elevation-1 w-table"
              height="57vh"
              show-select
              :single-select="true"
              @item-selected="handleSelectItem"
              :loading="loading"
              :options.sync="options"
              :server-items-length="paginated.total"
              loading-text="Loading... Please wait"
            >
              <template v-slot:top>
                <span
                  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                >
                  Todos los productos
                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>

                <span v-if="item.active" class="activo">
                  <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                  activo
                </span>
                <span v-else class="inactivo">
                  <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                  inactivo
                </span>

                <!--
                <span v-if="item.offices && item.offices.length == 1 && item.offices[0].active" class="activo">
                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                    activo
                </span>
                <span v-else class="inactivo">
                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                    inactivo
                </span>
                -->
              </template>

              <template v-slot:item.category_id="{ item }">
                <span v-if="item.category">{{ item.category.name }}</span>
              </template>

              <template v-slot:item.brand_id="{ item }">
                <span v-if="item.brand">{{ item.brand.name }}</span>
              </template>

              <template v-slot:item.unit_id="{ item }">
                <span v-if="item.unit">{{ item.unit.name }}</span>
              </template>

              <template v-slot:item.purchase_price="{ item }">
                <span>{{ item.purchase_price | currency("S/") }}</span>
              </template>

              <template v-slot:item.base_price="{ item }">
                <span>{{ item.base_price | currency("S/") }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet
        v-model="show_options_crud"
        :hide-overlay="true"
        persistent
        :retain-focus="false"
      >
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn
              v-if="this.$hasPermision('productos.edit')"
              class="mt-3"
              dark
              color="blue"
              outlined
              @click="handleEdit"
            >
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn
              v-if="
                show_btn_active && this.$hasPermision('productos.change_status')
              "
              class="ml-4 mt-3"
              dark
              color="green"
              outlined
              @click="handleChangeStatus"
            >
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn
              v-if="
                !show_btn_active &&
                  this.$hasPermision('productos.change_status')
              "
              class="ml-4 mt-3"
              dark
              color="amber"
              outlined
              @click="handleChangeStatus"
            >
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn
              v-if="this.$hasPermision('productos.destroy')"
              class="ml-4 mt-3"
              dark
              color="red"
              outlined
              @click="handleDelete"
            >
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <Filters ref="filters"></Filters>

    <download-products ref="downloadProducts"></download-products>
    <upload-products ref="uploadProducts"></upload-products>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router/index";
import ArrayTools from "../../../helpers/ArrayTools";
import Filters from "../../../components/products/FiltersPaginated";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import DownloadProducts from "../../../components/products/DownloadProducts";
import UploadProducts from "../../../components/products/UploadProducts";

export default {
  name: "Products",
  components: {
    Filters,
    OfficeLogin,
    DownloadProducts,
    UploadProducts,
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        console.log(this.options);
        this.perPage = this.options.itemsPerPage;
        this.pageSelected = this.options.page;
        const search = this.search_product;
        console.log(search);
        if (
          this.filtersPaginated.name != null ||
          this.filtersPaginated.sku != null ||
          this.filtersPaginated.barcode != null ||
          this.filtersPaginated.category_id != null ||
          this.filtersPaginated.brand_id != null ||
          this.filtersPaginated.unit_id != null ||
          this.filtersPaginated.office_id != null
        ) {
          this.getProducts({
            paginated: true,
            page: this.pageSelected,
            itemsPerPage: this.perPage,
            name: this.filtersPaginated.name,
            sku: this.filtersPaginated.sku,
            barcode: this.filtersPaginated.barcode,
            category_id: this.filtersPaginated.category_id,
            brand_id: this.filtersPaginated.brand_id,
            unit_id: this.filtersPaginated.unit_id,
            office_id: this.filtersPaginated.office_id,
          })
            .then((res) => (this.loading = false))
            .catch((err) => (this.loading = false));
        } else {
          if (search != null && search.trim().length > 0) {
            // this.perPage = -1
            this.getProducts({
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
              name: search,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          } else {
            this.getProducts({
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      category_selected: null,
      show_celulares: false,
      show_accesorios: true,
      show_chips: false,
      title_table: null,
      search_product: null,
      drawer: false,
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],
      itemsBreadcrumbs: [
        {
          text: "Logística",
          disabled: true,
          href: "",
        },
      ],
      headers: [
        { text: "NOMBRE", value: "name", width: 500 },
        { text: "CATEGORIA", value: "category_id" },
        { text: "MARCA", value: "brand_id" },
        { text: "CODIGO DE BARRAS", value: "barcode" },
        { text: "UND. DE MEDIDA", value: "unit_id" },
        { text: "PRECIO DE COMPRA", value: "purchase_price", align: "end" },
        { text: "PRECIO DE VENTA", value: "base_price", align: "end" },
      ],
      loading: false,
      dataTable: { page: 1 },
      options: {},
      pageSelected: 1,
      perPage: 10,
    };
  },
  computed: {
    ...mapState("authentication", ["office_id"]),
    ...mapState("offices", ["offices"]),
    ...mapState("products", [
      "products",
      "selected_product",
      "paginated",
      "filtersPaginated",
    ]),
    ...mapState("categories", ["categories"]),
  },
  mounted() {
    this.changeFilterPaginated({
      name: null,
      company_id: null,
    });
  },
  methods: {
    ...mapActions("products", [
      "getProducts",
      "change_status",
      "delete",
      "runCleanProducts",
      "selectProduct",
      "emptyProducts",
      "changeFilterPaginated",
    ]),
    ...mapActions("categories", ["getCategories"]),
    ...mapActions("brands", ["getBrands"]),
    ...mapActions("units", ["getUnits"]),
    ...mapActions("offices", ["getOffices"]),
    async onPageChange(page) {
      this.loading = true;
      await this.getProducts({ page: page, paginated: true })
        .then((result) => (this.loading = false))
        .catch((errr) => (this.loading = false));
    },
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0;
      if (this.show_options_crud) {
        //let item = item.offices && item.offices.length == 1 && item.offices[0].active
        let item = this.selectedRows[0];
        if (item.offices && item.offices.length == 1) {
          this.show_btn_active = !item.offices[0].active;
        }
        //this.show_btn_active = !this.selectedRows[0].active
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item);
      } else {
        let index_found = ArrayTools.getIndexById(
          this.selectedRows,
          selected.item.id
        );
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1);
        }
      }
      this.showOptionCrud();
    },
    handleShowCreate() {
      router.push("/app/logistica/datos-producto");
    },
    handleEdit() {
      let product = this.selectedRows[0];
      this.selectedRows = [];
      this.showOptionCrud();
      this.selectProduct(product);
      router.push("/app/logistica/datos-producto");
    },
    handleShowFilters() {
      this.$refs.filters.showForm("Filtrar datos de productos");
    },
    handleInputSearchByEnter(KeyboardEvent) {
      if (KeyboardEvent.code == "Enter") {
        this.handleSearch();
      }
    },
    async handleSearch() {
      this.selectedRows = [];
      this.changeFilterPaginated({
        name: null,
        sku: null,
        barcode: null,
        category_id: null,
        brand_id: null,
        unit_id: null,
        office_id: null,
      });
      this.showOptionCrud();
      this.loading = true;
      let filters = {};
      this.dataTable.page = 1;
      if (this.search_product.trim().length > 0) {
        filters = {
          name: this.search_product,
          office_id: this.office_id,
        };
      } else {
        filters = {
          paginated: true,
        };
      }
      await this.getProducts(filters)
        .then((result) => (this.loading = false))
        .catch((errr) => (this.loading = false));
    },
    async handleChangeStatus() {
      let product = this.selectedRows[0];
      this.selectedRows = [];
      this.showOptionCrud();
      await this.change_status(product);
    },
    async handleDelete() {
      let product = this.selectedRows[0];
      await this.delete(product);
      this.selectedRows = [];
      this.showOptionCrud();
    },
    handleDownload() {
      this.$refs.downloadProducts.show();
    },
    handleUpload() {
      this.$refs.uploadProducts.show();
    },
    async load() {
      this.loading = true;
      this.getCategories();
      this.getBrands();
      this.getUnits();
      this.getOffices();
      let filters = {
        office_id: this.office_id,
        paginated: true,
      };
      // await this.getProducts(filters).then(res=>this.loading=false)
      // .catch(err=>this.loading=false);
    },
  },
  created() {
    this.emptyProducts();
    this.selectProduct(null);
    this.load();
  },
  destroyed() {
    this.emptyProducts();
  },
};
</script>

<style scoped>
.section-categories {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif !important;
}

.header-table-products {
  vertical-align: baseline !important;
  padding-top: 5px !important;
}

.v-data-table-header > tr > th {
  color: #263238 !important;
  vertical-align: baseline !important;
  padding-top: 5px !important;
}
</style>
